import React from "react";
import { Link, graphql } from "gatsby";

import { Bio, SubscribeEmail } from "../components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";

class BlogPostTemplate extends React.Component {
    render() {
        const { pageContext, data, location } = this.props;
        const { previous, next } = pageContext;
        const { markdownRemark: post, site } = data;
        const { title: siteTitle } = site.siteMetadata;

        const descriptionExt = post.frontmatter.descriptionExt;
        const description = post.frontmatter.description;
        let desc = "";
        if (description && descriptionExt) desc = `${description} ${descriptionExt}`;
        
        return (
            <Layout location={location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={desc || post.excerpt}
                />
                <article>
                    <header>
                        <h1
                            style={{
                                marginTop: rhythm(1),
                                marginBottom: 0,
                            }}
                        >
                            {post.frontmatter.title}
                        </h1>
                        <p
                            style={{
                                ...scale(-1 / 5),
                                display: `block`,
                                marginBottom: rhythm(1),
                            }}
                        >
                            {post.frontmatter.date}
                        </p>
                    </header>
                    <section dangerouslySetInnerHTML={{ __html: post.html }} />
                    <hr
                        style={{
                            marginBottom: rhythm(1),
                        }}
                    />
                    <footer>
                        <Bio location={location} />
                        <SubscribeEmail />
                    </footer>
                </article>

                <nav>
                    <ul
                        style={{
                            display: `flex`,
                            flexWrap: `wrap`,
                            justifyContent: `space-between`,
                            listStyle: `none`,
                            padding: 0,
                        }}
                    >
                        <li>
                            {previous && (
                                <Link to={previous.fields.slug} rel="prev">
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link to={next.fields.slug} rel="next">
                                    {next.frontmatter.title} →
                                </Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`;
