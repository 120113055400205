/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon,
} from "react-share";

import { rhythm } from "../../utils/typography";

import s from "./Bio.module.scss";

const shareConfig = [
    { button: FacebookShareButton, icon: FacebookIcon },
    { button: TwitterShareButton, icon: TwitterIcon },
    { button: LinkedinShareButton, icon: LinkedinIcon },
    { button: RedditShareButton, icon: RedditIcon },
];

const Bio = ({ location }) => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            site {
                siteMetadata {
                    author
                }
            }
        }
    `);

    const { author } = data.site.siteMetadata;

    const shareUrl = `${location.origin}${location.pathname}`;

    return (
        <div
            style={{
                marginBottom: rhythm(2.5),
            }}
        >
            <div
                style={{
                    display: `flex`,
                    marginBottom: rhythm(0.75),
                }}
            >
                <Image
                    fixed={data.avatar.childImageSharp.fixed}
                    alt={author}
                    style={{
                        marginRight: rhythm(1 / 2),
                        marginBottom: 0,
                        minWidth: 50,
                        borderRadius: `100%`,
                    }}
                    imgStyle={{
                        borderRadius: `50%`,
                    }}
                />
                <div>
                    <p style={{ marginBottom: 0 }}>
                        Written by <strong>{author}</strong>
                    </p>
                    <p style={{ marginBottom: 0, marginTop: 10 }}>
                        Traveling doesn't have to be expensive. We're proving it
                        can be done on a budget: one day, one city at a time.
                    </p>
                    <a href="/about">about our blog</a>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "left",
                }}
            >
                {shareConfig.map(
                    (
                        {
                            button: Button,
                            icon: Icon,
                            buttonProps = {},
                            iconProps = {},
                        },
                        idx
                    ) => {
                        const isFirst = idx === 0;
                        const isLast = idx === shareConfig.length - 1;
                        return (
                            <Button
                                {...buttonProps}
                                key={idx}
                                url={shareUrl}
                                style={{
                                    marginLeft: isFirst ? 0 : rhythm(1 / 2.5),
                                    marginRight: isLast ? 0 : rhythm(1 / 2.5),
                                }}
                                className={s.socialBtn}
                            >
                                <Icon
                                    {...iconProps}
                                    size={32}
                                    round
                                    className={s.socialIcon}
                                />
                            </Button>
                        );
                    }
                )}
                <span
                    style={{
                        paddingTop: 10,
                        marginLeft: 15,
                        color: "#888",
                    }}
                >
                    ...share
                </span>
            </div>
        </div>
    );
};

export default Bio;
