import React from "react";

import styles from "./Input.module.scss";

export default ({ label, ...rest }) => (
    <label htmlFor="inp" className={styles.inp}>
        <input type="text" placeholder="&nbsp;" {...rest} />
        <span className={styles.label}>{label}</span>
        <span className={styles.border} />
    </label>
);
