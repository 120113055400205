import React, { useState, useEffect } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Input } from "../";
import styles from "./SubscribeEmail.module.scss";

const SUBSCRIBE_KEY = "__subscribed_to_emails__";

const SubscribeEmail = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [alreadySubscribed, setAlreadySubscribed] = useState(false);
    const [showThankyou, setShowThankyou] = useState(false);

    const _handleSubmit = async e => {
        e.preventDefault();
        if (loading) return;
        
        setLoading(true);
        try {
            const { result, msg } = await addToMailchimp(email);
            if (!/success/i.test(result)) {
                if (/already subscribed/i.test(msg)) return setSubscribed();

                setError(msg);
            } else {
                return setSubscribed();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const setSubscribed = () => {
        localStorage.setItem(SUBSCRIBE_KEY, 1);
        setShowThankyou(true);
        setTimeout(() => setAlreadySubscribed(true), 5000);
    }

    useEffect(() => {
        setAlreadySubscribed(localStorage.getItem(SUBSCRIBE_KEY));
    }, []);

    if (alreadySubscribed) return null;

    if (showThankyou) return <p>You will receive content updates to your email now!</p>;
    
    return (
        <form className={styles.form} onSubmit={_handleSubmit}>
            <span style={{ marginBottom: 30 }}>
                Receive updates of our latest content by subscribing
            </span>
            <div className={styles.inputs}>
                <Input
                    label="Email"
                    disabled={loading}
                    type="email"
                    name="email"
                    onChange={({ target: { value } }) => setEmail(value)}
                />
                <button className={styles.button}>Subscribe</button>
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </form>
    );
};
export default SubscribeEmail;
